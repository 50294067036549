<template>
  <div class="lg:mt-3">
    <vs-card>
      <div class="flex flex-col lg:flex-row">
        <div class="w-full flex items-center space-x-2 pt-2">
          <h5 class="text-xl font-semibold leading-5">Order #{{ item.orderNumber }}</h5>
        </div>

        <div class="w-full lg:w-1/2 flex items-center justify-end space-x-2 mt-3 lg:mt-0 ">
          <vs-button v-if="!isAdmin" type="filled" color="success" icon-pack="feather" icon="icon-phone" class="flex-1 text-sm lg:text-md" @click="handleContact(item._id)">Contact Us</vs-button>
          <vs-button type="filled" color="primary"  v-if="item.sageOrderInvoiceNumber || hasSageOrderInvoiceNumber" :href="getPDFLink(item)" :download="getName(item)" target="_blank" class="flex-1 text-sm lg:text-md"> View Invoice PDF </vs-button>
          <vx-tooltip v-else :text="invoiceTooltipText" class="flex-1 text-sm lg:text-md">
            <vs-button type="filled" class="w-full text-gray-400 text-sm lg:text-md bg-gray-200 pointer-events-none opacity-80">
              <div class="flex items-center gap-3">
                <span>
                  View Invoice PDF
                </span>
                <div class="flex w-5" v-if="isPollingSageOrderInvoiceNumber">
                  <img src="../../../../../public/loader.gif" class="w-full h-full"/>
                </div>
              </div>
            </vs-button>
          </vx-tooltip>
        </div>
    </div>

    <hr class="border-t border-solid border-gray-200 my-4" />

    <div class="grid grid-cols lg:grid-cols-4 gap-6">
      <div>
          <h6 class="mb-2 font-semibold lg:font-medium">Order Details</h6>
          <div class="w-full flex flex-col space-y-1 text-sm">
            <p>Clinic: {{ item.shippingDetails.companyName }}</p>
            <p>Order Date: {{ item.createdAt | date_formatter }}</p>
            <p>Order Time: {{ formatTime(item.createdAt) }} AEDT</p>
            <p v-if="item.shippingDetails.notes">Notes: {{ item.shippingDetails.notes }}</p>
          </div>
      </div>
      <div>
          <h6 class="mb-2 font-semibold lg:font-medium">Shipping Address</h6>
          <div class="w-full flex flex-col space-y-1 text-sm">
            <p>{{ item.shippingDetails.address.displayAddress }}</p>
          </div>
      </div>
      <div>
          <h6 class="mb-2 font-semibold lg:font-medium">Tracking Info</h6>
          <div class="w-full flex flex-col space-y-1 text-sm">
              <vue-ckeditor  v-if="isAdmin" v-model="item.trackingLink" :config="config" class="mb-2 font-semibold" />
              <div v-else v-html="item.trackingLink" class="w-full overflow-x-auto" />
              <vs-button v-if="isAdmin" class="mr-2 md:mb-2 font-semibold" @click="updateTrackingInfo">Save</vs-button><vs-button v-if="isAdmin" @click="sendTrackingUpdateEmail">Send Email</vs-button>
          </div>
      </div>
      <div>
          <h6 class="mb-2 font-semibold lg:font-medium">Clinic Hours of Operation</h6>
          <div class="w-full flex flex-col space-y-1 text-sm">
              <p><strong class="font-medium mr-1">Monday: </strong>{{ item.operations ? item.operations.monday : '' }}</p>
              <p>
              <strong class="font-medium mr-1">Tuesday: </strong>{{ item.operations ? item.operations.tuesday : '' }}
              </p>
            <p>
              <strong class="font-medium mr-1">Wednesday: </strong
              >{{ item.operations ? item.operations.wednesday : '' }}
            </p>
            <p>
              <strong class="font-medium mr-1">Thursday: </strong>{{ item.operations ? item.operations.thursday : '' }}
            </p>
            <p><strong class="font-medium mr-1">Friday: </strong>{{ item.operations ? item.operations.friday : '' }}</p>
            <p>
              <strong class="font-medium mr-1">Saturday: </strong>{{ item.operations ? item.operations.saturday : '' }}
            </p>
            <p><strong class="font-medium mr-1">Sunday: </strong>{{ item.operations ? item.operations.sunday : '' }}</p>
          </div>
      </div>
    </div>

    </vs-card>

    <vs-card class="p-1 lg:p-3">
      <table class="table w-full">
        <thead>
           <tr>
            <th scope="col" class="p-2">Product Details</th>
            <th scope="col" class="text-center p-2">SKU</th>
            <th scope="col" class="text-center p-2">Unit Price</th>
            <th scope="col" class="text-center p-2">Total Discount</th>
            <th scope="col" class="text-center p-2">Amount Paid</th>
           </tr>
        </thead>
        <tbody>
          <tr :data="tr" v-for="(val, index) in item.items" v-bind:key="index">
            <td data-label="Product Details">
              <div class="w-full flex flex-col lg:flex-row items-center">
                <div v-if="val.product.images.length" class="mt-4 lg:mt-0">
                  <img class="w-32 lg:w-24 mx-auto lg:m-4" :src="val.product.images[0].image" alt="" />
                </div>
                <div class="py-4 lg:p-4 w-full">
                  <p class="font-semibold text-base mb-1 text-left" style="text-wrap: balance">{{ productName(val.product) }}</p>
                  <p class="text-left">
                    Qty: {{ val.quantity }}
                    <span class="text-yellow-600"
                      >{{ currency }}
                      {{
                        (val.costPrice / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      }}
                    </span>
                  </p>

                  <div class="w-full pt-2" v-if="val.bundleItems">
                    <p class="ql-align-center" v-for="(items, index) in val.bundleItems" :key="index">
                      <span v-if="!items.isFree || items.isFree === undefined">qty: x{{ items.quantity }} - {{ items.name }}</span>
                    </p>
                    <p class="ql-align-center italic pt-2" v-if="val.bundleItems.length && val.bundleItems.filter(item => item.isFree).length">
                      <span>Free Items: </span>
                    </p>
                    <p class="ql-align-center" v-for="(items, index) in val.bundleItems" :key="index">
                      <span v-if="items.isFree">qty: x{{ items.quantity }} - {{ items.name }}</span>
                    </p>
                  </div>
                  <ul class="w-full lg:w-1/2 pt-2 text-left text-sm" v-if="val.patient">
                      <li class="grid grid-cols-2 ">
                        <span>First name</span>
                        <span>{{ val.patient.firstName }}</span>
                      </li>
                      <li class="grid grid-cols-2 ">
                        <span>Last name</span>
                        <span>{{ val.patient.lastName }}</span>
                      </li>
                      <li class="grid grid-cols-2 ">
                        <span>Contact #</span>
                        <span>{{ val.patient.contact }}</span>
                      </li>
                      <li class="grid grid-cols-2 ">
                        <span>Address</span>
                        <span>{{ val.patient.address }}</span>
                      </li>
                      <li class="grid grid-cols-2 ">
                        <span>State</span>
                        <span>{{ val.patient.state }}</span>
                      </li>
                      <li class="grid grid-cols-2 ">
                        <span>Suburb</span>
                        <span>{{ val.patient.suburb }}</span>
                      </li>
                      <li class="grid grid-cols-2 ">
                        <span>Postcode</span>
                        <span>{{ val.patient.zipcode }}</span>
                      </li>
                      <li class="grid grid-cols-2 ">
                        <span>Date of Birth</span>
                        <span>{{ formatDOB(val.patient.dob) }}</span>
                      </li>
                      <li v-if="!isAU" class="grid grid-cols-2 ">
                        <span>Allergies</span>
                        <span>{{ val.patient.allergies }}</span>
                      </li>
                      <li class="grid grid-cols ">
                        <p class="py-1">
                          <vs-icon icon="check" v-if="val.patient.isForPatient"></vs-icon>
                          <vs-icon icon="radio_button_unchecked" v-if="!val.patient.isForPatient"></vs-icon>
                          I confirm this script is for a patient
                        </p>
                      </li>
                      <li class="grid grid-cols ">
                        <p class="py-1">
                          <vs-icon icon="check" v-if="!val.patient.isForPatient"></vs-icon>
                          <vs-icon icon="radio_button_unchecked" v-if="val.patient.isForPatient"></vs-icon>
                          I confirm this script is for me, as my own patient
                        </p>

                      </li>
                    </ul>
                </div>
              </div>
            </td>
            <td data-label="Unit Price" class="text-base text-left lg:text-center mb-2">
              {{ val.supplierSKU }}
            </td>
            <td data-label="Unit Price" class="text-base text-left lg:text-center mb-2">
              ${{ getUnitPrice(val) }}
            </td>
            <td data-label="Total Discount" class="text-base text-left lg:text-center mb-2">
              ${{ getTotalDiscount(val) }}
            </td>
            <td data-label="Amount Paid" class="text-base text-left lg:text-center mb-2">
              ${{ getAmountPaid(val) }}
            </td>
          </tr>
        </tbody>
      </table>

      <hr class="lg:hidden border-t border-solid border-gray-100" />

      <div class="grid grid-cols gap-2 lg:w-4/12 ml-auto my-8 px-3 lg:px-0">
        <div class="grid grid-cols lg:grid-cols-3">
          <span class="text-base font-semibold lg:font-normal col-span-2">Subtotal</span>
          <p class="text-base lg:text-right"> ${{ (item.subTotal / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) }} </p>
        </div>
        <div class="grid grid-cols lg:grid-cols-3" v-for="(val, index) in discounts" v-bind:key="index">
          <span class="text-base font-semibold lg:font-normal col-span-2">{{val.discountLabel}}</span>
          <p class="text-base lg:text-right"> ${{ (val.totalDiscount / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) }} </p>
        </div>
        <div class="grid grid-cols lg:grid-cols-3">
          <span class="text-base font-semibold lg:font-normal col-span-2">Delivery</span>
          <p class="text-base lg:text-right"> ${{ (item.shippingCharge / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) }} </p>
        </div>
        <div class="grid grid-cols lg:grid-cols-3">
          <span class="text-base font-semibold lg:font-normal col-span-2">2% card transaction fee applies</span>
          <p class="text-base lg:text-right">
             ${{
                (item.creditCardTransactionFee / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              }}
          </p>
        </div>
        <div class="grid grid-cols lg:grid-cols-3" v-if="isAU">
          <span class="text-base font-semibold lg:font-normal col-span-2">GST(10%)</span>
          <p class="text-base lg:text-right">
             ${{ (item.gst / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) }}
          </p>
        </div>
      </div>

      <hr class="border-t border-solid border-gray-100" />

      <div class="grid grid-cols gap-2 lg:w-4/12 ml-auto my-4 px-3 lg:px-0">
        <div class="grid grid-cols lg:grid-cols-3">
          <span class="text-base font-semibold lg:font-normal col-span-2">Total</span>
          <p class="text-base lg:text-right">
             ${{
                (item.total / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
          </p>
        </div>
      </div>


    </vs-card>

    <vs-popup
        title="Contact Account Manager"
        :active.sync="contactManager"
      >
      <a :href="'tel:'+contactNumber" class="flex items-center space-x-4 px-8 py-5">
        <div class="flex items-center justify-center bg-primary text-white rounded-full w-10 h-10 lg:w-12 lg:h-12">
          <feather-icon icon="PhoneIcon" svgClasses='h-6 w-6' />
        </div>
        <div>
          <h4 class="text-base lg:text-lg"> Contact Account Manager </h4>
          <p class="text-lg font-medium mt-1 lg:mt-0"> {{ contactNumber }} </p>
        </div>
      </a>
    </vs-popup>
  </div>
</template>

<script>
import moment from 'moment';
import VueCkeditor from 'vue-ckeditor2';
import { mapActions } from 'vuex';

export default {
  name: 'OrderTable',
  components: {
    VueCkeditor,
  },
  data() {
    return {
      isAU: process.env.VUE_APP_REGION === 'AU',
      config: {
        toolbar: [
          {
            name: 'clipboard',
            items: ['Undo', 'Redo'],
          },
          { name: 'styles', items: ['Format'] },
          {
            name: 'basicstyles',
            items: ['Bold', 'Italic', 'Strike'],
          },
          { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
        ],
        versionCheck: false,
      },
      discounts: [],
      userData: null,
      contactManager: false,
      contactNumber: '',
      pollingInterval: null,
      pollCountLimit: 6,
      hasSageOrderInvoiceNumber: false,
      isPollingSageOrderInvoiceNumber: false,
      invoiceTooltipText: 'Retrieving Invoice...',
    };
  },
  props: ['item', 'isAdmin'],
  methods: {
    ...mapActions('storeOrder', ['updateOrderTrackingInfo', 'sendTrackingToClinicFromAdmin', 'getOrderDetails']),
    ...mapActions("appUser", ["saveContactLogs"]),
    ...mapActions("admin", ["getSettingsByKey"]),

    async getExistingSettings() {
      const { data: { data } } = await this.getSettingsByKey("companyContactNumber")
      this.contactNumber = data.value
    },
    productName(result) {
      try {

        if(this.item.customer && this.item.customer.isDoctorStore){
          return result.productName
        }
        return result.name
      }
      catch(e){
        console.log(e)
        return null
      }
    },
    associatedBundledItems(productId) {
      const bundled = this.item.bundled || []
      return bundled.filter(({ parentProductId }) => parentProductId === productId)
    },
    async sendTrackingUpdateEmail() {
      try {
        this.$vs.loading();
        const res = await this.sendTrackingToClinicFromAdmin({ id: this.item._id });
        this.$vs.loading.close();
        this.$vs.notify({
          title: res.data.title,
          text: res.data.message,
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
        });
      } catch (error) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: error.data.title,
          text: error.data.message,
          color: 'danger',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
        });
      }
    },
    async updateTrackingInfo() {
      try {
        this.$vs.loading();
        const res = await this.updateOrderTrackingInfo({
          orderNumber: this.item.orderNumber,
          body: {
            trackingLink: this.item.trackingLink,
          },
        });
        this.$vs.loading.close();
        this.$vs.notify({
          title: res.data.title,
          text: res.data.message,
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
        });
      } catch (error) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: error.data.title,
          text: error.data.message,
          color: 'danger',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
        });
      }
    },
    async handleContact(orderId) {
      this.contactManager = true;
      const res = await this.saveContactLogs({
        userId: this.userData._id,
        name: this.userData.firstName + " " + this.userData.lastName,
        orderId
      })
    },  
    getName(row) {
      const companyName = row.shippingDetails.companyName ? row.shippingDetails.companyName : 'NA';

      if(row.invoice) {
        if (!this.isAdmin) {
          if (row.invoice && row.invoice.length) return `${row.invoice[0].invoiceNumber} ${companyName}`;
        } else {
          if (Object.keys(row.invoice).length) return `${row.invoice.invoiceNumber} ${companyName}`;
        }
      }
    },
    getPDFLink(item) {
      if(item.invoice) {
        if (!this.isAdmin) {
          if (item.attachments && item.attachments.length) {
            return item.attachments[0].path;
          } else if(Object.keys(item.invoice).length) {
            if(Array.isArray(item.invoice) && item.invoice.length) {
              return item.invoice[0].pdfLink;
            }
            return item.invoice.pdfLink;
          }
        } else {
          if (Object.keys(item.invoice).length) return item.invoice.pdfLink;
        }
      }
      return '';
    },
    formatDOB(dob) {
      return moment(dob).format('DD MMM YYYY');
    },
    formatTime(date) {
      return moment(date).format('HH:mm');
    },
    getTotalDiscount(val) {
      return  ((val.discountPerItem * val.quantity) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
    },
    getAmountPaid(val) {
      return  ((val.costPrice * val.quantity) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })
    },
    getUnitPrice(val) {
      return (val.costPrice / 100).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },
    async checkInvoiceNumber() {
      await this.getOrderDetails(this.item._id).then((res) => {
        if (res.data.data.result[0].sageOrderInvoiceNumber) {
          this.hasSageOrderInvoiceNumber = true;
          this.stopPolling();
        }
      });
    },
    startPolling() {
      this.isPollingSageOrderInvoiceNumber = true;
      let pollingCounter = 0;
      this.pollingInterval = setInterval(() => {
        if (pollingCounter < this.pollCountLimit) {
          if (!this.item.sageOrderInvoiceNumber) {
            this.checkInvoiceNumber()
          } else {
            this.stopPolling()
          }
        } else {
          this.invoiceTooltipText = 'Invoice not available';
          this.stopPolling()
        }

        pollingCounter++;
      }, 5000) // 5 seconds
    },
    stopPolling() {
      this.isPollingSageOrderInvoiceNumber = false;
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval)
      }
    },
  },
  watch: {
    item: function(val) {
      if(this.item.discounts) {
        this.discounts = this.item.discounts.filter((val)=>val.isAdjusted === false);
      }
    }
  },
  created() {
    this.userData = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}User`)
    );

    this.getExistingSettings()
    
    const items = this.item.items.map((val) => {
      const product = this.item.product.filter(({ _id }) => _id.toString() === val.productStoreId.toString());
      return {
        ...val,
        product: product[0],
      };
    });

    this.item = {
      ...this.item,
      items,
    };
  },
  mounted() {
    if (!this.item.sageOrderInvoiceNumber) {
      this.startPolling()
    }
  },

  beforeDestroy() {
    this.stopPolling()
  }
};
</script>

<style>
  .con-vs-popup .vs-popup {
    width: 450px;
  }
</style>

<style scoped>
  table thead tr th {
    border-top: solid 1px rgba(61, 201, 179, 0.3);
    border-bottom: solid 1px rgba(61, 201, 179, 0.3);
    background-color: rgba(61, 201, 179, 0.06);
    color: rgba(61, 201, 179, 0.7);
  }

  table thead tr th:first-child {
    border-left: solid 1px rgba(61, 201, 179, 0.3);
  }

  table thead tr th:last-child {
    border-right: solid 1px rgba(61, 201, 179, 0.3);
  }

  table tbody tr td {
    border-bottom: solid 1px rgba(61, 201, 179, 0.3);
  }

  table tbody tr td:first-child {
    border-left: solid 1px rgba(61, 201, 179, 0.3);
  }

  table tbody tr td:last-child {
    border-right: solid 1px rgba(61, 201, 179, 0.3);
  }

  /* Responsive <table> */
  @media screen and (max-width: 992px) {
    table {
        min-width: unset;
    }
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      display: none !important;
    }
    table tr {
      border: none !important;
      display: block;
      margin-bottom: .625em;
      background-color: #f9f9f9;
      padding: 1rem;
      border-radius: 0.4rem;
    }
    table td {
      border: none !important;
      display: block;
      font-size: 1rem;
      text-align: right;
    }
    table td:first-child{
      min-width: 230px;
    }
    table td::before {
      content: attr(data-label);
      display: block;
      text-align: left;
      font-size: .95rem;
      font-weight: 600;
    }
    table td:last-child {
      border-bottom: 0;
    }
  }

</style>
